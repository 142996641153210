.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.table-responsive {
  padding-top: 15px;
}

@media only screen and (max-width: 900px) {
  /* Force table to not be like tables anymore */
  .table-responsive table, .table-responsive thead, .table-responsive tbody, .table-responsive th, .table-responsive td, .table-responsive tr {
    display: block;
  }
  /* Hide table headers (but not display: none;, for accessibility) */
  .table-responsive thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  .table-responsive td {
    /* Behave  like a "row" */
    border: none;
    position: relative;
  }
  .table-responsive td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }
  /* .table-actions {
    text-align: left;
  } */
  /* .table-responsive .table tbody tr {
    box-shadow: none;
    border-bottom: 1px solid #e9ecef;
  } */
  /* .table-responsive .table tbody tr .responsive-mobile-heading {
    display: inline-block;
    width: 40%;
    color: #212529;
    font-weight: bold;
  } */
}

.action-header {
  width: 200px;
}

.w-100 {
  width: 100%;
}

h3 {
  margin: 0
}

.mobile-nav-link {
  margin-top: 24px;
  margin-bottom: 24px;
}

.active-mobile-nav-link {
  text-decoration: none;
  color: #641197;
  font-weight: bold;
}

.inactive-mobile-nav-link {
  text-decoration: none;
  color: black;
  font-weight: bold;
}

.active-nav-link {
  padding-left: 24px;
  text-decoration: none;
  color: #641197;
  font-weight: bold;
}

.inactive-nav-link {
  padding-left: 24px;
  text-decoration: none;
  color: black
}